import { render, staticRenderFns } from "./file.vue?vue&type=template&id=f1a0ed34&scoped=true&"
import script from "./file.vue?vue&type=script&lang=babel&"
export * from "./file.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a0ed34",
  null
  
)

export default component.exports